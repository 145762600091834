<template>
  <div class="Xiangmu_c_tx">
    <p class="Xiangmu_c_txtitle">在线填写调研分析与决策</p>
    <ul class="Xiangmu_c_txul">
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          一、项目名称
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r" :class="titletetx">
          {{ xmalltitle }}
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          二、项目初步认知
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="（提示:设计师对于本设计项目/本品牌/该公司的具体了解）"
            v-model="renzhi"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          三、工作计划
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="（提示:设计师对于本设计项目流程的大致构思以及时间规划）"
            v-model="jihua"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          四、项目背景及现状
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="请输入内容"
            v-model="beijing"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          五、市场调研
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="（提示:可以从PEST分析、产品分析、竞品分析、消费者分析等方面切入）"
            v-model="diaoyan"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          六、设计需求
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="（提示:企业方的设计需求是怎样的）"
            v-model="xuqiu"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          七、痛点分析
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="（提示:分析该项目存在的问题）"
            v-model="tongdian"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
      <li class="Xiangmu_c_txulli" :style="chakantype">
        <div class="Xiangmu_c_txulli_l">
          八、设计建议
          <span v-if="wenhao">?</span>
        </div>
        <div class="Xiangmu_c_txulli_r">
          <el-input
            :disabled="disabledtype"
            v-if="inpttype"
            type="textarea"
            :autosize="autosize"
            placeholder="(提示:就以上综合分析，设计师给出设计方面的建议，在接下来的设计中设计师打算如何去做）"
            v-model="jianyi"
          >
          </el-input>
          <div class="Xiangmu_c_txulli_rtetx" v-if="texttype">测试</div>
        </div>
      </li>
    </ul>
    <div class="Xiangmu_c_txbtn">
      <el-button plain v-if="downtype">
        <a :href="href" target="view_window" >下载</a>
      </el-button>
      <el-button plain v-if="false">暂存草稿</el-button>
      <el-button type="primary" v-if="bianjibtn" @click="subdiaoyan"
        >提交</el-button
      >
      <el-button plain v-if="ckbtn" @click="shenhediaoyan(taotainum)">{{
        taotaitext
      }}</el-button>
      <el-button
        type="primary"
        v-if="ckbtn"
        @click="shenhediaoyan(undertake)"
        >{{ ruweitext }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      downtype:false,
      textarea: "",
      wenhao: true, //问号显示
      chakantype: "display:flex", //折行？
      texttype: false, //文字显示
      inpttype: true, //输入框显示
      titletetx: "", //Xiangmu_c_txulli_r_zhehang 类名
      autosize:{ minRows: 6 }, //默认显示行数
      bianjibtn: true,
      ckbtn: false,
      xmalltitle: "",
      task_id: "",
      r_id: "", //计划书id

      renzhi: "", //认知
      jihua: "", //计划
      beijing: "", //背景
      diaoyan: "", //调研
      xuqiu: "", //需求
      tongdian: "", //痛点
      jianyi: "", //建议

      disabledtype: false,
      userid: "",
      userinfo: {},
      undertake: 0,
      ruweitext: "",
      taotaitext: "",
      taotainum: "",
      href:"",
    };
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    if(this.userinfo.groupid==2){
      this.downtype=true;
    }
    this.xmalltitle = that.$route.query.xmalltitle;
     document.title=that.$route.query.xmalltitle+"调研分析与决策";
    this.task_id = that.$route.query.data;
    this.userid = that.$route.query.userid;
    if (that.$route.query.r_id) {
      that.disabledtype = true;
      that.r_id = that.$route.query.r_id;
      that.bianjibtn = false;
      that.ckbtn = true;
      that
        .$axios({
          url: "/api/getFindReport",
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          method: "post",
          data: {
            id: that.r_id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.href="https://api.hozoin.cn/api/exportWord?id="+res.data.result.id;
            that.renzhi = res.data.result.early;
            that.jihua = res.data.result.plan;
            that.beijing = res.data.result.project;
            that.diaoyan = res.data.result.market;
            that.xuqiu = res.data.result.demand;
            that.tongdian = res.data.result.analyze;
            that.jianyi = res.data.result.advice;
          } else {
            this.$message({
                    message: res.data.messages,
                    duration: 1000,
                  });

          }
        });
    } else {
    }
    this.$axios({
      url: "/api/getProjectDetail",
      method: "post",
      data: {
        id: that.task_id,
      },
    }).then((res) => {
      if (res.data.result.undertake == "1") {
        that.undertake = "5";
        that.ruweitext = "通过审核";
        that.taotaitext = "返回";
        that.taotainum = "88";
      } else {
        that.undertake = "4";
        that.ruweitext = "设为入围";
        that.taotaitext = "淘汰";
        that.taotainum = "2";
      }
    });
  },
  methods: {
    subdiaoyan() {
      var that = this;
      that
        .$axios({
          url: "/api/addReportInfo",
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          method: "post",
          data: {
            task_id: that.task_id,
            early: that.renzhi,
            plan: that.jihua,
            project: that.beijing,
            market: that.diaoyan,
            demand: that.xuqiu,
            analyze: that.tongdian,
            advice: that.jianyi,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$router.push({
              path: "/Xiangmu_c",
              query: { data: that.task_id },
            });
          } else {
            this.$message({
                    message: res.data.messages,
                    duration: 1000,
                  });

          }
        });
    },
    shenhediaoyan(checked) {
      var that = this;
      if (checked == 88) {
        this.$router.go(-1);
      } else {
        that
          .$axios({
            url: "/api/project/getCancelSetUp",
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            method: "post",
            data: {
              userid: that.userid,
              task_id: that.task_id,
              checked: checked,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              that.$router.push({
                path: "/Xiangmu_c",
                query: { data: that.task_id },
              });
            } else {
              this.$message({
                    message: res.data.messages,
                    duration: 1000,
                  });

            }
          });
      }
    },
  },
};
</script>
<style>
@import "../assets/css/xiangmu.css";
</style>
